import { Button, Chip } from '@mui/material';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import * as React from 'react';
import { pageImage } from './project_list.module.css';

const project_list = [
  {
    title: 'TokenTuna',
    chip: <Chip label="WEB APP" color="primary" style={{ marginLeft: '8px' }} />,
    text: (
      <>
        <p>
          My goal with this project was to build an ever-growing suite of research tools by and for NFT flippers. Inspired by the growing
          utilization of NFTs in the WEB3 space for everything from admission tickets to membership to DAOs, I wanted to contribute some of
          my skill set to building something useful for the growing community.
        </p>
        <p>
          With the Collection Appraiser tool, you'll be able to quickly get the keypoints about any collection like liquidity risk, value
          growth, etc. With the Wallet Tracker, you can check on the activity/performance of any wallet you know the public ethereum address
          of, whether you are trying to keep tabs on several of your own wallets, or keeping a watchful eye on some whales. More tools are
          in development!
        </p>
      </>
    ),
    link: (
      <Button variant="outlined" target="_blank" href="https://www.tokentuna.com/" style={{ marginTop: '16px' }}>
        visit
      </Button>
    ),
    image: <StaticImage layout="constrained" className={pageImage} height={600} src="../images/tokentuna.png" />
  },
  {
    title: 'WikiTrivia',
    chip: <Chip label="REST API | DAAS" color="primary" style={{ marginLeft: '8px' }} />,
    text: (
      <>
        <p>
          The purpose of this REST API is to provide a useful resource for up and coming game developers specialized in word based games who
          don't have the ability to amass a significant pool of content like large gaming companies. as someone who is a big fan of trivia
          style games, it always bothered me that there was never any sort of huge repository of questions you could dive into for fun or
          use for projects.
        </p>
        <p>
          I employed concurrent python data processing tasks to ingest all of English Wikipedia and associated companion datasets like
          categorical information and page view data in order to populate a NoSQL DynamoDB database full of questions that could be queried
          by category, and difficulty. The use of global secondary indexes and an appropriate table schema to allow for convenient access
          patterns meant that I could avoid time-costly scans completely and get lightning fast responses from any of the REST API's
          endpoints. I talk a bit more about what I did and allow you to fetch some random trivia on the WikiTrivia page which you can visit
          through the button below.
        </p>
      </>
    ),
    link: (
      <Button variant="outlined" LinkComponent={Link} to="/wikitrivia" style={{ marginTop: '16px' }}>
        visit
      </Button>
    ),
    image: <StaticImage layout="constrained" className={pageImage} height={600} src="../images/wikitrivia.png" />
  },
  {
    title: 'Highscores.gg',
    chip: <Chip label="WEB APP" color="primary" style={{ marginLeft: '8px' }} />,
    text: (
      <>
        <p>
          This project is a love letter to both video games and web scraping! As an avid video game lover I was frustrated by the lack of
          centralized resources to view all the news about any video games that struck my fancy. Luckily for me, I got super into web
          scraping a while ago and decided that I could build a web app that leveraged scheduled web scraping and data processing tasks via
          lambdas triggered by cloudwatch events (Thanks AWS!) to compile the data I wanted.
        </p>
        <p>
          Now with historical data of the popularity of games in the media, not only could I rank games weekly and watch out for new games
          that I might not have heard of, but with the incorporation of the 3rd party Twitch API I could get a sneak peek as to who was
          currently playing the games I was getting information about. All that was left was building out some REST API endpoints on API
          gateway and creating some dashboard templates with my favorite front end Javascript library (React) and Highscores.gg was
          constructed.
        </p>
      </>
    ),
    link: (
      <Button variant="outlined" target="_blank" href="https://www.highscores.gg/" style={{ marginTop: '16px' }}>
        visit
      </Button>
    ),
    image: <StaticImage layout="constrained" className={pageImage} height={600} src="../images/highscores.png" />
  },
  {
    title: 'GrandGizmos',
    chip: <Chip label="WEBSITE" color="primary" style={{ marginLeft: '8px' }} />,
    text: (
      <p>
        This website was my first venture into incorporating some extensive natural language processing, sentiment analysis, and web
        scraping into one project. After being repeatedly misled on Amazon on quite a few products by reviews that were clearly fake in
        retrospect, I decided to make this website to inspect the reviews of the top items in specific categories and report which would be
        the best product to purchase among the group. By analyzing the post history of users who posted reviews on Amazon products and
        aggregate analysis on all the reviews on a particular products, I was able to filter out a lot of paid for/bot reviews and also get
        an idea of what the strengths and weaknesses of each product was.
      </p>
    ),
    link: (
      <Button variant="outlined" target="_blank" href="https://www.grandgizmos.com/" style={{ marginTop: '16px' }}>
        visit
      </Button>
    ),
    image: <StaticImage layout="constrained" className={pageImage} height={600} src="../images/grandgizmos.png" />
  }
];

export default project_list;
